<template>
  <div class="addvote">
    <!-- 步骤条 -->
    <div class="steps">
      <Steps :current="current">
        <Step title="基本内容"></Step>
        <Step title="规则配置"></Step>
        <Step title="高级设置"></Step>
      </Steps>
    </div>
    <!-- 主体内容 -->
    <div class="container">
      <!-- 左侧菜单 -->
      <Row class="left-list">
        <Menu theme="light" :active-name="current" @on-select="selectMenu">
          <MenuItem :name="item.id" v-for="item in menuList" :key="item.id">{{
            item.name
          }}</MenuItem>
        </Menu>
      </Row>

      <!-- 右侧表单 -->
      <div class="right-content">
        <keep-alive>
          <component
            :is="componentId"
            :formValidate="formValidate"
            @nextStep="nextStep"
            @save="publish"
            :ref="componentId"
            @reject="reject"
          ></component>
        </keep-alive>
      </div>
    </div>
    <!-- 驳回框 -->
    <LiefengModal title="驳回原因" :value="reasonModal" @input="reasonStatusFn">
      <template v-slot:contentarea>
        <Form
          :model="formValidate"
          :rules="reasonValidate"
          :label-colon="true"
          ref="reasonForm"
        >
          <FormItem prop="reason">
            <Input v-model.trim="formValidate.reason" :maxlength="20"></Input>
          </FormItem>
        </Form>
      </template>
      <template v-slot:toolsbar>
        <Button type="info" @click="reasonStatusFn(false)" style="margin-right: 10px"
          >取消</Button
        >
        <Button type="primary" @click="reasonSubmit">保存</Button>
      </template>
    </LiefengModal>
    <Spin fix v-if="spinShow"></Spin>
  </div>
</template>

<script>
import { format } from "@/utils/formatTime.js";
import baseInfo from "@/views/answer/childrens/publishBaseInfo";
import highSetting from "@/views/answer/childrens/publishHighSetting";
import ruleConfig from "@/views/answer/childrens/publishRuleConfig";
import LiefengModal from "@/components/LiefengModal";
export default {
  props: ["infoId","menuCodeObj","orgCode"],
  data() {
    return {
      spinShow: false,
      //标题
      title: "",
      //驳回框状态
      reasonModal: false,
      //驳回表单验证
      reasonValidate: {
        reason: [
          { required: true, message: "请输入驳回原因", trigger: "blur" },
        ],
      },
      //当前右侧表单数据
      formValidate: {
        reason: "", //驳回原因
        title: "", //标题
        source: "", //信息来源
        subtype: "", //信息类型
        appointmentPhone: "", //联系电话（预约号码）
        startTime: "", //开始时间
        subStartTime: "",
        endTime: "", //结束时间
        subEndTime: "",
        provinceCode: 44, //省编码
        cityCode: "", //城市编码
        areaCode: "", //区县编码
        streetCode: "", //街道编码
        projectCodeList: "", //社区编码
        city: "", //修改时地址
        content: "", //富文本

        photo: "", //封面图片上传路径
        settingImgPath: "", //高级设置背景图片

        voteTypeList: [], //投票方式列表

        //规则配置
        questionBankId: "", // 题库类型
        userVerified: "0", //实名认证 0 不需要 1 需要
        period: "", //周期设置 全程0 每天 24 小时 0-24 ,
        answerNumber: "", //答题局数 ,
        roundQuestionNumber: "1", //每局题目数
        questionLimit: "1", //每题限时 s
        participantsNumber: "1", //参与人数
        winnersNumber: "1", //中奖人数
        awardMethod: "", //评奖方式
        effectiveTime: "", //有效领奖开始时间 ,
        expirationTime: "", //有效领奖截止时间

        valiStartTime: "", //每天答题起始时间 格式00:00:00 ,
        valiEndTime: "", // 每天答题截止时间 格式00:00:00 ,
        whetherComment: "0", //是否开启评论 0 否 1 是 ,
        recommend:'0', //是否推荐 0 否 1是

        modifier: parent.vue.loginInfo.userinfo.nickName, //修改人
        staffId: parent.vue.loginInfo.userinfo.id,
        status: 1,
        creatorId: parent.vue.loginInfo.userinfo.id,
      },
      //当前右侧组件ID
      componentId: "baseInfo",
      //步进器进度
      current: 0,
      //左侧菜单
      menuList: [
        {
          id: 0,
          name: "基本内容",
        },
        {
          id: 1,
          name: "规则配置",
        },
        {
          id: 2,
          name: "高级设置",
        },
      ],
    };
  },
  watch: {
    current(newVal) {
      this.$emit("comSelectMenu", newVal);
    },
  },
  created() {
    //获取数据字典信息
    Promise.all([
      this.getDictionaries("VOTE_TYPE"),
      this.getDictionaries("VOTE_CYCLE"),
      this.getDictionaries("VOTE_ITEM_SEQ"),
      this.getDictionaries("VOTE_ITEM_NAME"),
      this.getDictionaries("VOTE_BUTTON_NAME"),
      this.getDictionaries("VOTE_BUTTON_UNIT"),
      this.getDictionaries("VOTE_DISCUSS"),
    ]).then((res) => {
      this.formValidate.voteTypeList = res[0].dataList;
      this.formValidate.voteCycleList = res[1].dataList;
      this.formValidate.voteItemSeqList = res[2].dataList;
      this.formValidate.voteItemNameList = res[3].dataList;
      this.formValidate.buttonNameList = res[4].dataList;
      this.formValidate.buttonUnitList = res[5].dataList;
      this.formValidate.openDiscussList = res[6].dataList;
    });

    this.spinShow = true;
    this.$get("/voteapi/api/pc/affiche/getAfficheInfoManager", {
      infoId: this.infoId,
    }).then((res) => {
      if (res.code == 200) {
        this.formValidate.city = res.dataList[0];
      }
    });
    //获取信息详情
    this.$get("/voteapi/api/pc/affiche/emp/queryById", {
      operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      orgCode: parent.vue.loginInfo.userinfo.orgCode,
      oemCode: parent.vue.oemInfo.oemCode,
       businessType: this.menuCodeObj.businessType,
      functionType: this.menuCodeObj.functionType,
      infoId: this.infoId,
    })
      .then((res) => {
        if (res.code == 200) {
          let startTime = new Date(res.data.startTime);
          let endTime = new Date(res.data.endTime);
          res.data.startTime = format(startTime, "yyyy-MM-dd HH:mm:ss");
          res.data.endTime = format(endTime, "yyyy-MM-dd HH:mm:ss");
          for (let k in res.data) {
            if (this.formValidate.hasOwnProperty(k)) {
              this.formValidate[k] = res.data[k];
            }
            if (k == "answer") {
              for (let j in res.data.answer) {
                if (this.formValidate.hasOwnProperty(j)) {
                  if (j == "status") continue;
                  if (j == "endTime" || j == "startTime") continue;
                  this.formValidate[j] = res.data.answer[j];
                }
                if (j == "ruleVo") {
                  for (let i in res.data.answer.ruleVo) {
                    if (this.formValidate.hasOwnProperty(i)) {
                      this.formValidate[i] = res.data.answer.ruleVo[i];
                    }
                  }
                }
              }
            }
          }
          this.title =
            this.formValidate.status == "1"
              ? "答题审核"
              : this.formValidate.status == "3"
              ? "答题发布"
              : "答题过期";
          this.$emit("modalStatusFn", this.formValidate.status);
          this.spinShow = false;
          //获取信息类型
          this.$get("/voteapi/api/pc/menu/getMenuParam", {
            columnCode: this.$core.getUrlParam("menuId") || "",
            orgCode: parent.vue.loginInfo.userinfo.orgCode,
          }).then((res) => {
            this.subtypeList = res;
            this.subtypeList.map((item, index) => {
              if (this.formValidate.subtype == item.value) {
                this.formValidate.subtype = item.label;
              }
            });
          });
        } else {
          this.$Message.error({
            background: true,
            content: "获取答题信息失败！",
          });
          this.spinShow = false;
        }
      })
      .catch((err) => {
        this.$Message.error({
          background: true,
          content: "获取答题信息失败！",
        });
        this.spinShow = false;
      });
  },
  methods: {
    // 通过并加急发布
    passAndSend(){
      this.$Modal.confirm({
        title: "加急说明",
        content: "<p>1.经审核提交发布的合格内容，在点击【加急】按钮后，该信息将通过【加急】通道，在15分钟内完成发布流程。</p><p> 2.根据市民政局主管部门的批准意见，【加急】发布的信息，仅限于疫情防控或类似需要紧急发布的信息，具体标准由审核人员参照批准意见加强管控。</p>",
        onOk: () => {
           this.publish(1)
        }
      })  
    },
    //驳回状态
    reasonStatusFn(status) {
      this.reasonModal = status;
    },
    //点击发布
    publish(releaseWay) {
      this.$post("/voteapi/api/pc/affiche/emp/changeAffiche", {
        infoId: this.infoId,
        status:
          this.formValidate.status == "1"
            ? "3"
            : this.formValidate.status == "3"
            ? "4"
            : "5",
        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        orgCode: this.orgCode,
        oemCode: parent.vue.oemInfo.oemCode,
        businessType: this.menuCodeObj.businessType,
        functionType: this.menuCodeObj.functionType,
        reason: this.formValidate.reason,
        releaseWay:releaseWay ? releaseWay : '',
        pushType:this.formValidate.pushType && this.formValidate.pushType[0] ? this.formValidate.pushType[0]:'',
        channels:this.formValidate.pushType && this.formValidate.pushType[0] == 3 ? this.formData.channels:'',
      })
        .then((res) => {
          if (res.code == 200) {
            this.$Message.success({
              background: true,
              content: "提交成功！",
            });
            this.$emit("pageChange", {
              page: 1,
              pageSize: 10,
            });
            //重新获取信息状态的数量
            this.$emit("messageStatusCount");
            this.$emit("addChangeModalFn", false);
          } else {
            this.$Message.error({
              background: true,
              content: "提交失败！",
            });
          }
        })
        .catch((err) => {
          this.$Message.error({
            background: true,
            content: "提交失败！",
          });
        });
    },
    //点击驳回按钮
    reject() {
      this.reasonModal = true;
    },
    //驳回提交
    reasonSubmit() {
      this.$refs.reasonForm.validate((res) => {
        if (res) {
          this.$post("/voteapi/api/pc/affiche/emp/changeAffiche", {
            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            orgCode: parent.vue.loginInfo.userinfo.orgCode,
            oemCode: parent.vue.oemInfo.oemCode,
             businessType: this.menuCodeObj.businessType,
            functionType: this.menuCodeObj.functionType,
            infoId: this.infoId,
            status: 2,
            reason: this.formValidate.reason,
          })
            .then((res) => {
              if (res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: "驳回成功！",
                });
                this.$emit("pageChange", {
                  page: 1,
                  pageSize: 10,
                });
                //重新获取信息状态的数量
                this.$emit("messageStatusCount");
                this.$emit("addChangeModalFn", false);
              } else {
                this.$Message.error({
                  background: true,
                  content: "驳回失败！",
                });
              }
            })
            .catch((err) => {
              this.$Message.error({
                background: true,
                content: "驳回失败！",
              });
            });
        }
      });
    },
    getDictionaries(type) {
      //获取数据字典数据
      return this.$get("/voteapi/api/common/sys/findDictByDictType", {
        dictType: type,
      });
    },
    //步进器上下一步
    nextStep(value) {
      this.selectMenu(this.current + value);
    },
    selectMenu(name) {
      this.current = name;
      switch (name) {
        case 0:
          this.componentId = "baseInfo";
          break;
        case 1:
          this.componentId = "ruleConfig";
          break;
        default:
          this.componentId = "highSetting";
      }
    },
  },
  components: {
    baseInfo,
    highSetting,
    ruleConfig,
    LiefengModal,
  },
};
</script>

<style scoped lang='less'>
.addvote {
  background: #fcfcfc;
  text-align: left;

  .steps {
    height: 80px;
    margin: 20px 50px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .ivu-steps {
      width: 80%;
    }
    .ivu-steps-status-process,
    .ivu-steps-status-finish {
      /deep/.ivu-steps-head-inner {
        border-color: #12b26d;
        background-color: #12b26d;
      }
    }
    /deep/.ivu-steps-icon {
      color: #fff;
    }
    /deep/.ivu-steps-item.ivu-steps-status-finish .ivu-steps-tail > i:after {
      background: #12b26d;
    }
    /deep/.ivu-steps-status-error .ivu-steps-icon {
      color: #ed4014;
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin: 0 50px;
    min-height: calc(100vh - 222px);
    .ivu-menu-item {
      color: #303133;
      background: #fff;
    }
    .ivu-menu-item:hover {
      color: #409eff;
    }
    .ivu-menu-item-selected.ivu-menu-item-active {
      background: #fff;
      color: #409eff;
    }
    .left-list {
      height: 100%;
      width: 220px;
    }
    /deep/.ivu-menu {
      text-align: center;
      width: 220px !important;
      background: #fcfcfc;
      li {
        background: #fcfcfc;
      }
      li,
      .ivu-menu-item-selected:hover,
      li:hover {
        color: #12b26d;
      }
      .ivu-menu-item-selected {
        color: #12b26d;
      }
    }
    .ivu-menu-vertical.ivu-menu-light:after {
      width: 2px;
    }
    .ivu-menu-item-active:not(.ivu-menu-submenu):after {
      background: #12b26d;
    }
    .right-content {
      flex: 1;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      background: #fff;
      height: calc(100vh - 222px);
      padding: 20px;
    }
  }
}
/deep/.vertical-center-modal .ivu-modal-body {
  padding: 16px;
}
</style>

<template>
  <div class="high-setting">
    <Form
      ref="formValidate"
      :model="formValidate"
      :label-width="160"
      :label-colon="true"
      :rules="ruleValidate"
    >
      <FormItem label="每天答题起始">
        <FormItem  style="float: left">
        <div v-if="formValidate.valiStartTime">
          <span>每天</span>
          <span style="font-size: 18px;margin: 0 5px">{{formValidate.valiStartTime}}</span>
          <span>之前不能答题</span>
        </div>
         </FormItem>
         <FormItem  style="margin-left: 30px;float: left">
        <div v-if="formValidate.valiEndTime">
          <span>每天</span>
          <span style="font-size: 18px;margin: 0 5px">{{formValidate.valiEndTime}}</span>
          <span>之后不能答题</span>
        </div>
         </FormItem>
         <div v-if="!formValidate.valiStartTime && !formValidate.valiEndTime">无</div>
      </FormItem>
      <!-- 暂时不加 -->
      <!-- <FormItem label="成功分享邀请新用户" prop="openDiscuss">
        <RadioGroup v-model="formValidate.openDiscuss">
          <Radio :label="item.dictKey" v-for="(item,index) in formValidate.openDiscussList" :key="index">{{item.dictValue}}</Radio>
        </RadioGroup>
        <div style="margin-left: 10px;display: inline-block" v-if="formValidate.openDiscuss == formValidate.openDiscussList[0].dictKey">
          <InputNumber :max="10" :min="1" v-model="formValidate.second"></InputNumber>
          <span style="margin-left: 10px">次答题机会</span>
        </div>
      </FormItem> -->
      <FormItem label="评论">
        <p>{{ formValidate.whetherComment == '2' ? '关闭' : '开启' }}</p>
      </FormItem>
      <!-- 暂时不加 -->
      <!-- <FormItem label="封面图片" class="form-item" style="width: 100%">
         <img ref="image" src="" alt=""  width="200">
        <Upload
            :before-upload="beforeImage"
            :show-upload-list="false"
            :action="actionImage"
            :data="uploadImageData"
            :on-success="(value, file) => imageSuccess(file)"
            :format="['jpg', 'jpeg', 'png']"
            :on-format-error="imageError"
          >
            <Button type="info">上传图片</Button>
          </Upload>
      </FormItem> -->

    </Form>
  </div>
</template>

<script>
export default {
  props: ["formValidate", "ruleValidate"],
  data() {
    return {
      formData: {},
    };
  },
  methods: {
    backStep() {
      this.$emit("nextStep", -1);
    },
    reject() {
       this.$emit("reject");
    },
    save() {
      this.$emit("save");
    },
  },
};
</script>

<style scoped lang='less'>
.high-setting {
  width: 100%;
  .ivu-date-picker {
    margin: 0 6px;
  }
  .bottom {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    button {
      margin-left: 20px;
    }
  }
}
</style>
